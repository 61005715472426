<template>
  <v-app>
    <v-navigation-drawer class="drawer" v-model="drawer" app centered>
      <div class="burger">
        <div class="logo"></div>
        <div class="menu">
          <a @click="drawer = false" href="#park" class="menu-item">АВТОПАРК</a>
          <a @click="drawer = false" href="#price" class="menu-item">ПРАЙС</a>
          <a
            @click="
              vacancy = true;
              drawer = false;
            "
            class="menu-item"
            >ВАКАНСИИ</a
          >
        </div>
        <a
          :href="
            `tel:${data.acf.telefon
              .replace('+7', '8')
              .replaceAll('(', '')
              .replaceAll(')', '')
              .replaceAll('-', '')
              .replaceAll(' ', '')}`
          "
          class="tel"
        >
          ТЕЛ.: {{ data.acf.telefon }}
        </a>
      </div>
    </v-navigation-drawer>

    <div class="mobile-back">
      <div class="mobile-overlay"></div>
    </div>
    <header>
      <div class="header-overlay">
        <div class="container">
          <div class="top-bar">
            <div class="logo"></div>
            <v-app-bar-nav-icon
              class="burger-btn"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <div class="menu">
              <a href="#park" class="menu-item">АВТОПАРК</a>
              <a href="#price" class="menu-item">ПРАЙС</a>
              <a @click="vacancy = true" class="menu-item">ВАКАНСИИ</a>
            </div>
            <a
              :href="
                `tel:${data.acf.telefon
                  .replace('+7', '8')
                  .replaceAll('(', '')
                  .replaceAll(')', '')
                  .replaceAll('-', '')
                  .replaceAll(' ', '')}`
              "
              class="tel"
            >
              ТЕЛ.: {{ data.acf.telefon }}
            </a>
          </div>
          <div class="main-image"></div>
          <div class="services">
            <div class="spacer">
              <div class="send-btn" @click="openPop()">
                Отправить заявку на услуги
                <img src="../public/arrow-o.png" class="arrow" alt="Заказать" />
                <div class="send-btn-orange">
                  Отправить заявку на услуги
                  <img src="../public/arrow-w.png" class="arrow" alt="Заказать" />
                </div>
              </div>
            </div>
            <div class="line-1"></div>
            <div class="line-2"></div>
            <div class="line-3"></div>
            <div class="line-4"></div>
            <div class="line-5"></div>
            <div
              class="service-wrapper"
              v-for="(service, index) in data.acf.uslugi"
              :key="index"
              @click="openPop(service.nazvanie_uslugi)"
            >
              <div :id="'service-' + index" class="service-title">
                {{ service.nazvanie_uslugi }}
              </div>
              <img
                class="service-image"
                :id="'s-img-' + index"
                :src="service.izobrazhenie_uslugi.url"
              />
              <div class="service-wrapper-orange">
                <div :id="'service-' + index + index" class="service-title-orange">
                  {{ service.nazvanie_uslugi }}
                </div>
                <button class="service-zakaz">Заказать</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section>
      <div class="sect-overlay grad">
        <div class="container">
          <h3>Почему Грузовичок?</h3>
          <div class="whys">
            <div
              class="why-wrapper"
              v-for="(why, index) in data.acf.pochemu_gruzovichok"
              :key="index"
            >
              <div class="why-container">
                <div class="why-first">
                  <div class="why-title">{{ why.priemushhestvo }}</div>
                  <img class="why-image" :src="why.izobrazhenie.url" />
                </div>
                <div class="why-second">
                  <p class="why-dop">{{ why.tekst_pri_navedenii }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="sect-overlay">
        <div class="container" id="price">
          <h3>Прайс</h3>
          <div class="prices">
            <div class="price-row" v-for="(price, index) in data.acf.prajs" :key="index">
              <div class="price-wrapper">
                <div class="price-img-wrapper">
                  <img :src="price.izobrazhenie.url" class="price-icon" />
                </div>
                <div class="price-text">{{ price.nazvanie }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="sect-overlay">
        <div class="container" id="park">
          <h3>Наш автопарк</h3>
          <div class="park-holder">
            <div
              class="park"
              v-for="(image, imageIndex) in data.acf.avtopark"
              :key="imageIndex"
              @click="index = imageIndex"
              :style="{
                background: 'url(' + image.izobrazhenie + ') no-repeat center center / contain',
              }"
            >
              <div class="park-name">{{ image.nazvanie }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="sect-overlay">
        <div class="container" id="contacts">
          <h3>Контакты</h3>
          <div class="contacts-wrapper">
            <div class="col-2">
              <h4>Остались вопросы?</h4>
              <p>
                Свяжитесь с нами любым удобным способом, или заполните форму и мы перезвоним в
                течение 5 минут!
              </p>
              <a
                :href="
                  `tel:${data.acf.telefon
                    .replace('+7', '8')
                    .replaceAll('(', '')
                    .replaceAll(')', '')
                    .replaceAll('-', '')
                    .replaceAll(' ', '')}`
                "
              >
                <v-icon>mdi-phone</v-icon> {{ data.acf.telefon }}
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=79923121515&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!"
              >
                <v-icon>mdi-whatsapp</v-icon> WhatsApp
              </a>
              <a> <v-icon>mdi-email</v-icon> gruzovichok.tyumen@gmail.com </a>
            </div>
            <div class="col-2">
              <v-form ref="form2" v-model="valid2" lazy-validation>
                <v-text-field
                  :rules="notEmpty"
                  v-model="name"
                  label="Ваше имя"
                  required
                ></v-text-field>
                <v-text-field
                  :rules="notEmpty"
                  v-model="phone"
                  label="Телефон"
                  required
                ></v-text-field>
                <v-textarea v-model="comment" label="Комментарий"></v-textarea>
                <v-btn :loading="loading" :disabled="disabled" @click="send(2)">{{
                  textZayavka
                }}</v-btn>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="sect-overlay">
        <div class="container">
          <h3 class="otivi-title">Отзывы</h3>
          <v-carousel hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item v-for="item in data.acf.otzyvy" :key="item.otzyv">
              <div class="otzyv-wrapper">
                <div class="otziv-holder">
                  <p class="otziv-name">{{ item.imya }}</p>
                  <div class="stars">
                    <v-icon>mdi-star</v-icon>
                    <v-icon>mdi-star</v-icon>
                    <v-icon>mdi-star</v-icon>
                    <v-icon>mdi-star</v-icon>
                    <v-icon>mdi-star</v-icon>
                  </div>
                  <p class="otzyv">
                    {{ item.otzyv }}
                  </p>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </section>
    <section>
      <div class="sect-overlay">
        <div class="container">
          <h3>Схема проезда</h3>
          <Map />
        </div>
      </div>
    </section>
    <footer>
      <div class="container">
        <div class="footer">
          <div class="footer-services">
            <div
              v-for="(service, index) in data.acf.uslugi"
              :key="index"
              @click="openPop(service.nazvanie_uslugi)"
              class="footer-service-title"
            >
              {{ service.nazvanie_uslugi }}
            </div>
          </div>
          <div class="footer-contacts">
            <a
              :href="
                `tel:${data.acf.telefon
                  .replace('+7', '8')
                  .replaceAll('(', '')
                  .replaceAll(')', '')
                  .replaceAll('-', '')
                  .replaceAll(' ', '')}`
              "
            >
              <v-icon>mdi-phone</v-icon> {{ data.acf.telefon }}
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=79923121515&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!"
            >
              <v-icon>mdi-whatsapp</v-icon> WhatsApp
            </a>
            <a> <v-icon>mdi-email</v-icon> gruzovichok.tyumen@gmail.com </a>
          </div>
        </div>
      </div>
    </footer>
    <div v-if="popup" class="pop-up">
      <div class="pop-up-wrapper" v-if="popup">
        <h5>Оставьте заявку и мы свяжемся с вами!</h5>
        <div class="close-btn" @click="popup = false">+</div>
        <v-form ref="form1" v-model="valid1" lazy-validation>
          <v-select
            :rules="notEmpty"
            label="Услуга"
            required
            v-model="selectedService"
            :items="data.acf.uslugi"
            item-text="nazvanie_uslugi"
            item-value="nazvanie_uslugi"
          ></v-select>
          <div class="date-time">
            <div class="date">
              <p>Дата</p>
              <input type="date" v-model="date" />
            </div>
            <div class="date">
              <p>Время</p>
              <input type="time" v-model="time" />
            </div>
          </div>
          <v-text-field v-model="address" label="Адрес"></v-text-field>
          <v-text-field :rules="notEmpty" v-model="name" label="Ваше имя" required></v-text-field>
          <v-text-field :rules="notEmpty" v-model="phone" label="Телефон" required></v-text-field>
          <v-textarea v-model="comment" label="Комментарий"></v-textarea>
          <v-btn :loading="loading" :disabled="disabled" @click="send(1)">{{ textZayavka }}</v-btn>
        </v-form>
      </div>
    </div>
    <div v-if="vacancy" class="pop-up">
      <div class="pop-up-wrapper" v-if="vacancy">
        <h5>Оставьте резюме и мы свяжемся с вами!</h5>
        <div class="close-btn" @click="vacancy = false">+</div>
        <v-form ref="form3" v-model="valid3" lazy-validation>
          <v-select
            :rules="notEmpty"
            required
            label="Должность"
            v-model="selectedVacancy"
            :items="data.acf.vakansii"
            item-text="nazvanie_vakansii"
            item-value="nazvanie_vakansii"
          ></v-select>
          <v-text-field :rules="notEmpty" v-model="name" label="Ваше имя" required></v-text-field>
          <v-text-field :rules="notEmpty" v-model="phone" label="Телефон" required></v-text-field>
          <v-textarea v-model="comment" label="Комментарий"></v-textarea>
          <v-btn :loading="loading" :disabled="disabled" class="sk-btn" @click="send(3)">{{
            textZayavka
          }}</v-btn>
        </v-form>
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from 'axios';
import Map from './components/Map.vue';
export default {
  data() {
    return {
      notEmpty: [(v) => !!v || 'Обязательно!'],
      loading: false,
      disabled: false,
      textZayavka: 'Отправить заявку',
      avtopark: [],
      index: null,
      drawer: false,
      selectedVacancy: '',
      vacancy: '',
      comment: '',
      name: '',
      phone: '',
      address: '',
      time: '',
      date: '',
      selectedService: '',
      popup: false,
      fullPage: true,
      data: {},
      valid1: false,
      valid2: false,
      valid3: false,
    };
  },
  //Импорт данных из WP(Главная страница) в переменную data
  created() {
    axios.get('https://xn--72-dlcduho3acp7a4c.xn--p1ai/wp-json/wp/v2/pages/6').then((response) => {
      this.data = response.data;
      this.loading = false;
      for (let i = 0; i < this.data.acf.avtopark.length; i++) {
        this.avtopark.push(this.data.acf.avtopark[i].izobrazhenie);
      }
    });
  },
  components: { Map },
  methods: {
    send(type) {
      let dataForSend = new FormData();
      if (type === 1) {
        if (this.$refs.form1.validate()) {
          this.loading = true;

          dataForSend.append('type', 1);
          dataForSend.append('name', this.name);
          dataForSend.append('phone', this.phone);
          dataForSend.append('address', this.address);
          dataForSend.append('date', this.date);
          dataForSend.append('time', this.time);
          dataForSend.append('service', this.selectedService);
          dataForSend.append('comment', this.comment);
          axios
            .post('./mailer/mailer.php', dataForSend, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              console.log(response);
              this.loading = false;
              this.disabled = true;
              this.textZayavka = 'Отправлено';
            });
        }
      }
      if (type === 2) {
        if (this.$refs.form2.validate()) {
          this.loading = true;
          dataForSend.append('type', 2);
          dataForSend.append('name', this.name);
          dataForSend.append('phone', this.phone);
          dataForSend.append('comment', this.comment);
          console.log(dataForSend);
          axios
            .post('./mailer/mailer.php', dataForSend, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              console.log(response);
              this.loading = false;
              this.disabled = true;
              this.textZayavka = 'Отправлено';
            });
        }
      }
      if (type === 3) {
        if (this.$refs.form3.validate()) {
          this.loading = true;
          dataForSend.append('type', 3);
          dataForSend.append('vacancy', this.selectedVacancy);
          dataForSend.append('name', this.name);
          dataForSend.append('phone', this.phone);
          dataForSend.append('comment', this.comment);
          axios
            .post('./mailer/mailer.php', dataForSend, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              console.log(response);
              this.loading = false;
              this.disabled = true;
              this.textZayavka = 'Отправлено';
            });
        }
      }
    },
    openPop(item) {
      this.disabled = false;
      this.textZayavka = 'Отправить заявку';
      this.selectedService = item;
      this.popup = true;
    },
  },
};
</script>

<style>
.line-1 {
  width: 45%;
  border-bottom: 2px solid #4e4e4e;
  position: absolute;
  top: 128px;
  right: 75px;
}

.line-2 {
  position: absolute;
  border-bottom: 2px solid #4e4e4e;
  top: 263px;
  right: 75px;
  width: 93.6%;
}

.line-3 {
  display: none;
  position: absolute;
  border-bottom: 2px solid #4e4e4e;
  top: 400px;
  right: 75px;
  width: 93.6%;
}

.line-4 {
  display: none;
  position: absolute;
  border-bottom: 2px solid #4e4e4e;
  top: 535px;
  right: 75px;
  width: 93.6%;
}

.line-5 {
  display: none;
  position: absolute;
  border-bottom: 2px solid #4e4e4e;
  top: 670px;
  right: 75px;
  width: 93.6%;
}

.cool-lightbox__slide__img img {
  box-shadow: none !important;
}

.cool-lightbox {
  background: white !important;
}

.park-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.park-name {
  bottom: -20px;
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 18px;
  color: #ff6b00;
}

.park {
  flex: 1 1 200px;
  height: 200px;
  position: relative;
  cursor: pointer;
  margin: 20px 0;
}

.park-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.park:hover .park-overlay {
  opacity: 1;
}

.drawer {
  display: none !important;
}

footer {
  background: rgba(0, 0, 0, 0.12);
}

.footer-services {
  display: flex;
  max-height: 80px;
  flex-wrap: wrap;
  flex-direction: column;
}

.footer-service-title {
  padding: 0 20px;
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.footer-contacts {
  display: flex;
  flex-direction: column;
}

.v-btn {
  background: #ff6b00 !important;
  color: white !important;
  outline: none;
  padding: 7px 12px;
  width: 100%;
  border-radius: 0 !important;
}

.tel:hover,
.menu-item:hover {
  color: #ff8f40 !important;
}

h5 {
  font-weight: 500;
  font-size: 18px;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  height: 20px;
  width: 20px;
  transform: rotateZ(45deg);
  background: rgba(128, 128, 128, 0.534);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.close-btn:hover {
  background: gray;
  color: white;
  cursor: pointer;
}

.date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.date-time input {
  outline: none;
}

:root {
  --blue-color: rgb(244, 188, 66);
}

::selection {
  background: rgb(192, 192, 192);
}

.date-time {
  display: flex;
  border-bottom: 1px solid gray;
}

.pop-up {
  background: rgba(0, 0, 0, 0.212);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  height: 100vh;
}

.pop-up-wrapper {
  background: white;
  width: 400px;
  padding: 30px;
  position: relative;
  will-change: transform;
  animation: anim 0.3s forwards;
}

.arrow {
  height: 15px;
  margin-left: 20px;
}

.send-btn-orange {
  position: absolute;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 4px 40px;

  color: white;
  font-size: 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  background: #ff6b00;
  opacity: 0;
  text-shadow: none;
}

.send-btn-orange::after {
  background: inherit;
  position: absolute;
  border-right: 1px solid #ff6b00;
  border-top: 1px solid #ff6b00;
  transform: skew(-32deg);
  bottom: 0;
  height: 103%;
  right: -12px;
  content: '';
  width: 24px;
  z-index: 0;
  background: #ff6b00;
  align-items: center;
}

.send-btn {
  justify-content: center;
  cursor: pointer;

  width: 90%;
  position: absolute;
  top: 55px;
  left: 0;

  padding: 4px 40px;
  border-top: 1px solid #ff6b00;
  border-bottom: 1px solid #ff6b00;
  border-left: 1px solid #ff6b00;
  color: #ff6b00;
  font-size: 20px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  text-shadow: 0 0 3px black;
}

.send-btn:hover .send-btn-orange {
  opacity: 1;
}

.send-btn::after {
  background: inherit;
  position: absolute;
  border-right: 1px solid #ff6b00;
  border-top: 1px solid #ff6b00;
  transform: skew(-32deg);
  bottom: 0;
  height: 103%;
  right: -12px;
  content: '';
  width: 24px;
  z-index: 0;
}

.menu-item {
  margin: 0 30px;
}

.menu {
  display: flex;
}

.v-application a,
.tel,
.menu-item {
  color: #ff6b00 !important;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Arial', sans-serif;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  will-change: transform;
}

.tel,
.menu {
  align-self: center;
  font-weight: 600;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 400;
  position: relative;
  margin: 10px 0;
}

.logo {
  background: url('../public/logo.png') no-repeat center center / contain;
  height: 100px;
  width: 300px;
  margin-bottom: -50px;
}

@font-face {
  font-family: 'Frankl';

  src: url('./assets/fonts/framd.woff') format('woff'),
    url('./assets/fonts/framd.ttf') format('ttf');
}

h3 {
  color: #ff6b00;
  text-transform: capitalize;
  width: 600px;
  max-width: 100%;
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: normal;
  border-bottom: 2px solid #4e4e4e;
  text-align: center;
  margin: 0 5px;
}

.prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.price-zakaz {
  height: 40px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  background-color: #ff6b00 !important;
  color: white !important;
  position: relative;
  font-weight: 500;
  outline: none;
  z-index: 1;
  transition: all 0.3s;
  will-change: transform;
  transform: translateX(-200px);
  opacity: 0;
}

.price-zakaz::before {
  top: 0;
  bottom: 0;
  left: -25px;
  width: 0;
  height: 0;
  border-bottom: 40px solid #ff6b00;
  border-left: 25px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.price-zakaz::after {
  top: 0;
  bottom: 0;
  right: -25px;
  width: 0;
  height: 0;
  border-top: 40px solid #ff6b00;
  border-right: 25px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.price-row:nth-child(2n) .price-zakaz::before {
  top: 0;
  bottom: 0;
  left: -25px;
  width: 0;
  height: 0;
  border-top: 40px solid #ff6b00;
  border-left: 25px solid transparent;
  border-right: none;
  border-bottom: none;
  content: '';
  position: absolute;
  z-index: -1;
}

.price-row:nth-child(2n) .price-zakaz::after {
  top: 0;
  bottom: 0;
  right: -25px;
  width: 0;
  height: 0;
  border-bottom: 40px solid #ff6b00;
  border-right: 25px solid transparent;
  border-left: none;
  border-top: none;
  content: '';
  position: absolute;
  z-index: -1;
}

.price-row {
  display: flex;
  align-items: center;
}

.price-img-wrapper {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-text {
  font-weight: 400;
  font-size: 20px;
  color: #ff6b00;
  margin: 0 30px;
}

.price-icon {
  height: 40px;
}

.price-wrapper {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.12);
  margin: 5px;
  position: relative;
  flex: 1 1 300px;
  z-index: 5;
  height: 40px;
}

.price-wrapper::before {
  top: 0;
  bottom: 0;
  left: -25px;
  width: 0;
  height: 0;
  border-bottom: 40px solid rgba(0, 0, 0, 0.12);
  border-left: 25px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.price-wrapper::after {
  top: 0;
  bottom: 0;
  right: -25px;
  width: 0;
  height: 0;
  border-top: 40px solid rgba(0, 0, 0, 0.12);
  border-right: 25px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.price-row:nth-child(2n) .price-wrapper::before {
  top: 0;
  bottom: 0;
  left: -25px;
  width: 0;
  height: 0;
  border-top: 40px solid rgba(0, 0, 0, 0.12);
  border-left: 25px solid transparent;
  border-right: none;
  border-bottom: none;
  content: '';
  position: absolute;
  z-index: -1;
}

.price-row:nth-child(2n) .price-wrapper::after {
  top: 0;
  bottom: 0;
  right: -25px;
  width: 0;
  height: 0;
  border-bottom: 40px solid rgba(0, 0, 0, 0.12);
  border-right: 25px solid transparent;
  border-left: none;
  border-top: none;
  content: '';
  position: absolute;
  z-index: -1;
}

.v-application {
  font-family: 'Frankl', sans-serif !important;
}

.why-title {
  width: 80%;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  color: #ff6b00;
  font-size: 20px;
  margin-right: -45px;
  z-index: 100;
}

.why-wrapper:nth-child(4n) .why-title,
.why-wrapper:nth-child(5n) .why-title,
.why-wrapper:nth-child(6n) .why-title {
  margin-right: 0;
  margin-left: -45px;
}

.why-image {
  height: 90px;
}

.whys {
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
}

.why-first {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  padding: 20px 0;
  transition: all 0.3s;
  will-change: transform;
}

.why-second {
  transition: all 0.3s;
  will-change: transform;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.why-dop {
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #ff6b00;
}

.why-wrapper:hover .why-first,
.why-wrapper:hover .why-second {
  transform: translateY(-200px);
}

.why-container {
  overflow: hidden;
}

.why-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.12);
  margin: 5px 45px;
  padding: 10px -45px;
  position: relative;
  flex: 1 1 200px;
  z-index: 5;
  height: 200px;
}

.why-wrapper::before {
  top: 0;
  bottom: 0;
  left: -75px;
  width: 0;
  height: 0;
  border-bottom: 200px solid rgba(0, 0, 0, 0.12);
  border-left: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.why-wrapper::after {
  top: 0;
  bottom: 0;
  right: -75px;
  width: 0;
  height: 0;
  border-top: 200px solid rgba(0, 0, 0, 0.12);
  border-right: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.why-wrapper:nth-child(4n)::before,
.why-wrapper:nth-child(5n)::before,
.why-wrapper:nth-child(6n)::before {
  top: 0;
  bottom: 0;
  right: -75px;
  width: 0;
  height: 0;
  border-top: 200px solid rgba(0, 0, 0, 0.12);
  border-left: 75px solid transparent;
  border-bottom: none;
  content: '';
  position: absolute;
  z-index: -1;
}

.why-wrapper:nth-child(4n)::after,
.why-wrapper:nth-child(5n)::after,
.why-wrapper:nth-child(6n)::after {
  top: 0;
  bottom: 0;
  right: -75px;
  width: 0;
  height: 0;
  border-bottom: 200px solid rgba(0, 0, 0, 0.12);
  border-right: 75px solid transparent;
  border-top: none;
  content: '';
  position: absolute;
  z-index: -1;
}

.service-zakaz {
  outline: none;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  padding: 7px 12px;
  margin-right: 35px;
}

.service-wrapper::before {
  top: 0;
  bottom: 0;
  left: -75px;
  width: 0;
  height: 0;
  border-bottom: 125px solid rgba(0, 0, 0, 0.12);
  border-left: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.service-wrapper::after {
  top: 0;
  bottom: 0;
  right: -75px;
  width: 0;
  height: 0;
  border-top: 125px solid rgba(0, 0, 0, 0.12);
  border-right: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.service-title {
  width: auto;
  margin-right: -45px;
  text-indent: 12px;
  color: #ff6b00;
  font-weight: 400;
  margin-bottom: -20px;
}

.service-wrapper-orange {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #ff6b00;
  padding: 5px 0;
  height: 125px;
  z-index: 5;
  width: 100%;
  cursor: pointer;
  will-change: transform;
  transition: all 0.3s;
  opacity: 0;
}

.service-wrapper:hover .service-wrapper-orange {
  opacity: 1;
}

.service-title-orange {
  width: auto;
  margin-right: -45px;
  text-indent: 12px;
  color: white;
  font-weight: 400;
}

.service-wrapper-orange::before {
  top: 0;
  bottom: 0;
  left: -75px;
  width: 0;
  height: 0;
  border-bottom: 125px solid #ff6b00;
  border-left: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.service-wrapper-orange::after {
  top: 0;
  bottom: 0;
  right: -75px;
  width: 0;
  height: 0;
  border-top: 125px solid #ff6b00;
  border-right: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.service-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.12);
  margin: 5px 45px;
  padding: 5px 0;
  position: relative;
  height: 125px;
  z-index: 5;
  flex: 1 1 60px;
  cursor: pointer;
}

.services {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: -130px;
  padding: 0 30px;
  transition: all 0.3s;
}

.spacer {
  width: 54%;
  position: relative;
}

.service-image {
  height: 80px;

  margin-left: -35px;
}

.header-overlay {
  background: linear-gradient(#ffffff 80%, rgba(255, 255, 255, 0.882) 100%);
  width: 100%;
  height: 100%;
}

.container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
}

header {
  position: relative;
  height: 600px;
  animation: anim 0.8s forwards;
}

section {
  background: url('../public/fon.jpg') repeat center center / cover;

  position: relative;
  height: 600px;
  animation: anim 0.8s forwards;
}

@keyframes anim {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.main-image {
  background: url('../public/fon.jpg') repeat center center / cover;
  height: 400px;
  width: 100%;
  transform: rotateY(180deg);
  transform: rotateZ(180deg);
  position: relative;
}

.main-image::before {
  width: 300px;
  height: 0px;
  border-bottom: 70px solid white;
  border-left: 42px solid transparent;
  border-right: 0px solid transparent;
  content: '';
  bottom: 0;
  right: 0;
  position: absolute;
}

.main-image::after {
  width: 50%;
  height: 0px;
  border-top: 135px solid white;
  border-left: 0px solid transparent;
  border-right: 81px solid transparent;
  content: '';
  top: 0;
  left: 0;
  position: absolute;
}

section:nth-child(even) {
  transform: rotateX(180deg);
}

section:nth-child(even) .sect-overlay {
  transform: rotateX(180deg);
}

.sect-overlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.884);
}

.grad {
  background: linear-gradient(#ffffff 1%, rgba(255, 255, 255, 0.882) 100%);
}

.contacts-wrapper {
  display: flex;
  background: rgba(0, 0, 0, 0.12);
  padding: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 80%;
  position: relative;
  margin-top: 5px;
}

.contacts-wrapper:before {
  top: 0;
  bottom: 0;
  right: -75px;
  width: 0;
  height: 0;
  border-bottom: 390px solid rgba(0, 0, 0, 0.12);
  border-right: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: 0;
}

.contacts-wrapper:after {
  top: 0;
  bottom: 0;
  left: -75px;
  width: 0;
  height: 0;
  border-top: 390px solid rgba(0, 0, 0, 0.12);
  border-left: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: 0;
}

.col-2 {
  height: 350px;
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

h4 {
  color: #ff6b00;
  font-size: 24px;
  text-align: center;
}

.col-2 p {
  color: #4e4e4e;
  text-align: center;
  padding: 20px 0;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0 !important;
}

.v-application .col-2 .primary--text,
.v-application .pop-up .primary--text {
  color: #ff6b00 !important;
  caret-color: #ff6b00 !important;
}

.v-icon {
  color: #ff6b00 !important;
}

.col-2 a {
  text-align: center;
}

.v-btn .v-icon {
  color: white !important;
}

.v-carousel__controls button {
  background: none !important;
}

.stars {
  display: flex;
  justify-content: center;
}

.otzyv-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;
  margin: 0 auto;
}

.otziv-holder {
  background: rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  height: 250px;
}

.otziv-holder:before {
  top: 0;
  bottom: 0;
  left: -75px;
  width: 0;
  height: 0;
  border-bottom: 250px solid rgba(0, 0, 0, 0.12);
  border-left: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.otziv-holder::after {
  top: 0;
  bottom: 0;
  right: -75px;
  width: 0;
  height: 0;
  border-top: 250px solid rgba(0, 0, 0, 0.12);
  border-right: 75px solid transparent;
  content: '';
  position: absolute;
  z-index: -1;
}

.otzyv {
  margin-top: 30px;
  text-align: center;
  color: #4e4e4e;
}

.otziv-name {
  color: #ff6b00;
  font-size: 24px;
}

.sk-btn {
  transition: all 0.3s;
  will-change: transform;
  width: 100%;
}

.sk-btn:hover {
  transform: scale(1.05);
}

.otivi-title {
  margin-bottom: -106px;
}

#service-1,
#service-2,
#service-0,
#service-11,
#service-22,
#service-00 {
  margin-left: 20px;
}

.burger-btn {
  display: none !important;
}

.v-carousel__controls {
  display: none !important;
}

.mobile-back {
  display: none;
}

@media (max-width: 1174px) {
  .line-3 {
    display: flex;
  }
}

@media (max-width: 817px) {
  .line-4 {
    display: flex;
  }
}

@media (max-width: 692px) {
  .line-5 {
    display: flex;
  }
}

@media (max-width: 1020px) {
  .menu-item {
    margin: 0 10px;
  }

  section,
  header {
    height: auto;
  }

  section,
  header {
    background: none;
    padding: 10px;
  }

  .sect-overlay {
    background: transparent;
  }

  .mobile-back {
    background: url('../public/fon.jpg') repeat center center / cover;
    position: fixed;
    z-index: 0;
    height: 100vh;
    width: 100vw;
    display: block;
  }

  .mobile-overlay {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.884);
  }

  .spacer {
    width: 55%;
  }

  footer {
    background: rgba(0, 0, 0, 0.12);
    z-index: 555;
  }
}

@media (max-width: 970px) {
  .drawer {
    display: flex !important;
  }

  .burger .logo {
    width: 90%;
    margin-bottom: 10px;
  }

  .burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
  }

  .burger .menu {
    flex-direction: column;
    align-items: center;
  }

  .burger .menu-item {
    margin: 10px 0;
  }

  .burger .tel {
    margin-top: 40px;
  }

  .burger-btn {
    display: flex !important;
    background: none !important;
    color: #4e4e4e !important;
  }

  .burger-btn .v-icon {
    color: #4e4e4e !important;
    font-size: 30px !important;
  }

  .top-bar .menu,
  .top-bar .tel {
    display: none;
  }

  .why-wrapper:nth-child(3n) .why-title,
  .why-wrapper:nth-child(4n) .why-title {
    margin-right: 0px;
  }

  .why-wrapper:nth-child(5n) .why-title,
  .why-wrapper:nth-child(6n) .why-title {
    margin-right: -70px;
  }

  .why-wrapper:nth-child(3n)::before,
  .why-wrapper:nth-child(4n)::before {
    top: 0;
    bottom: 0;
    right: -75px;
    width: 0;
    height: 0;
    border-top: 200px solid rgba(0, 0, 0, 0.12);
    border-left: 75px solid transparent;
    border-bottom: none;
    content: '';
    position: absolute;
    z-index: -1;
  }

  .why-wrapper:nth-child(3n)::after,
  .why-wrapper:nth-child(4n)::after {
    top: 0;
    bottom: 0;
    right: -75px;
    width: 0;
    height: 0;
    border-bottom: 200px solid rgba(0, 0, 0, 0.12);
    border-right: 75px solid transparent;
    border-top: none;
    content: '';
    position: absolute;
    z-index: -1;
  }

  .why-wrapper:nth-child(5n)::before,
  .why-wrapper:nth-child(6n)::before {
    top: 0;
    bottom: 0;
    right: -75px;
    width: 0;
    height: 0;
    border-bottom: 200px solid rgba(0, 0, 0, 0.12);
    border-left: 75px solid transparent;
    border-top: none;
    content: '';
    position: absolute;
    z-index: -1;
  }

  .why-wrapper:nth-child(5n)::after,
  .why-wrapper:nth-child(6n)::after {
    top: 0;
    bottom: 0;
    right: -75px;
    width: 0;
    height: 0;
    border-top: 200px solid rgba(0, 0, 0, 0.12);
    border-right: 75px solid transparent;
    border-bottom: none;
    content: '';
    position: absolute;
    z-index: -1;
  }
}

@media (max-width: 870px) {
  .arrow {
    display: none;
  }

  .col-2 {
    flex: 1 1 200px;
    padding: 5px;
  }

  .otziv-holder {
    height: 300px;
  }

  .otziv-holder::before {
    border-bottom: 300px solid rgba(0, 0, 0, 0.12);
  }

  .otziv-holder::after {
    border-top: 300px solid rgba(0, 0, 0, 0.12);
  }

  .spacer {
    width: 56%;
  }

  .footer-services {
    max-height: 100px;
  }
}
@media (max-width: 741px) {
  .contacts-wrapper::before,
  .contacts-wrapper::after {
    display: none;
  }
}
@media (max-width: 720px) {
  .send-btn {
    display: none;
  }

  .spacer {
    width: 57%;
  }
}

@media (max-width: 676px) {
  .why-wrapper:nth-child(3)::before,
  .why-wrapper:nth-child(5)::before {
    top: 0;
    bottom: 0;
    right: -75px;
    width: 0;
    height: 0;
    border-bottom: 200px solid rgba(0, 0, 0, 0.12) !important;
    border-left: 75px solid transparent !important;
    border-top: none !important;
    content: '';
    position: absolute;
    z-index: -1;
  }

  .why-wrapper:nth-child(3)::after,
  .why-wrapper:nth-child(5)::after {
    top: 0;
    bottom: 0;
    right: -75px;
    width: 0;
    height: 0;
    border-top: 200px solid rgba(0, 0, 0, 0.12);
    border-right: 75px solid transparent;
    border-bottom: none;
    content: '';
    position: absolute;
    z-index: -1;
  }

  .why-wrapper:nth-child(2n)::before,
  .why-wrapper:nth-child(4n)::before,
  .why-wrapper:nth-child(6n)::before {
    top: 0;
    bottom: 0;
    right: -75px;
    width: 0;
    height: 0;
    border-top: 200px solid rgba(0, 0, 0, 0.12) !important;
    border-left: 75px solid transparent !important;
    border-bottom: none !important;
    content: '';
    position: absolute;
    z-index: -1;
  }

  .why-wrapper:nth-child(2n)::after,
  .why-wrapper:nth-child(4n)::after,
  .why-wrapper:nth-child(6n)::after {
    top: 0;
    bottom: 0;
    right: -75px;
    width: 0;
    height: 0;
    border-bottom: 200px solid rgba(0, 0, 0, 0.12);
    border-right: 75px solid transparent;
    border-top: none;
    content: '';
    position: absolute;
    z-index: -1;
  }

  .footer-services {
    max-height: fit-content;
  }
}

@media (max-width: 586px) {
  .price-text {
    margin: 0;
  }

  .contacts-wrapper::before {
    display: none;
  }

  .contacts-wrapper::after {
    display: none;
  }

  .otziv-holder {
    height: auto;
  }

  .otziv-holder::before,
  .otziv-holder::after {
    display: none;
  }

  .otivi-title {
    margin-bottom: -50px;
  }

  .spacer {
    width: 59%;
  }

  .price-text {
    font-size: 16px;
  }

  .price-icon {
    height: 30px;
  }
}

@media (max-width: 500px) {
  .line-1,
  .line-2,
  .line-3,
  .line-4,
  .line-5 {
    display: none;
  }

  .footer-services,
  .footer-contacts {
    align-items: center;
    margin: 10px 0;
    flex: 1 1 200px;
  }

  .services {
    margin-top: 0;
  }

  .footer-contacts a {
    flex-wrap: nowrap;
    display: flex;
  }

  .spacer {
    display: none;
  }

  .header-overlay {
    background: transparent;
  }

  .main-image::after,
  .main-image::before {
    display: none;
  }

  .main-image {
    height: 200px;
  }

  .logo {
    margin-bottom: 0;
  }

  .service-wrapper:nth-child(2n)::before {
    border-top: 125px solid rgba(0, 0, 0, 0.12);
    border-left: 75px solid transparent;
    border-bottom: none;
  }

  .service-wrapper:nth-child(2n)::after {
    border-bottom: 125px solid rgba(0, 0, 0, 0.12);
    border-right: 75px solid transparent;
    border-top: none;
  }

  .service-wrapper:nth-child(2n) .service-wrapper-orange::before {
    border-top: 125px solid #ff6b00;
    border-left: 75px solid transparent;
    border-bottom: none;
  }

  .service-wrapper:nth-child(2n) .service-wrapper-orange::after {
    border-bottom: 125px solid #ff6b00;
    border-right: 75px solid transparent;
    border-top: none;
  }

  .service-wrapper,
  .service-wrapper-orange {
    flex: 1 1 200px;
  }

  .service-wrapper:nth-child(2n) .service-title,
  .service-wrapper:nth-child(2n) .service-title-orange {
    margin-left: -60px !important;
    margin-right: 0 !important;
  }

  .contacts-wrapper {
    width: 100%;
  }

  .prices {
    padding: 10px;
  }

  .col-2 {
    height: auto;
  }

  .otivi-title {
    margin-bottom: 0px;
  }

  .price-wrapper {
    height: 50px;
  }

  .price-wrapper::before {
    border-bottom: 50px solid rgba(0, 0, 0, 0.12);
  }

  .price-wrapper::after {
    border-top: 50px solid rgba(0, 0, 0, 0.12);
  }

  .price-row:nth-child(2n) .price-wrapper::before {
    border-top: 50px solid rgba(0, 0, 0, 0.12);
    border-bottom: none;
  }

  .price-row:nth-child(2n) .price-wrapper::after {
    border-bottom: 50px solid rgba(0, 0, 0, 0.12);
    border-top: 0;
  }

  .otzyv-wrapper {
    width: 100%;
  }

  .why-wrapper:nth-child(2n) .why-title {
    margin-right: -20px;
  }

  h3 {
    text-align: center;
  }

  .why-wrapper:nth-child(3n) .why-title,
  .why-title {
    margin-right: -50px;
  }

  .why-wrapper:nth-child(4n) .why-title,
  .why-wrapper:nth-child(2n) .why-title {
    margin-right: 0px;
    margin-left: -20px;
  }
}

@media (max-width: 350px) {
  #s-img-3 {
    margin-right: -65px;
  }
  .why-dop {
    font-size: 16px;
  }

  .why-wrapper:nth-child(3n) .why-title,
  .why-title {
    margin-right: -20px;
  }

  .why-wrapper:nth-child(4n) .why-title,
  .why-wrapper:nth-child(2n) .why-title {
    margin-right: 0px;
    margin-left: -20px;
  }
}

.cool-lightbox-button {
  background: transparent !important;
}

.footer-contacts a {
  color: #4e4e4e !important;
  font-weight: 500;
  font-family: Frankl, sans-serif;
  font-size: 16px;
}

.footer-contacts .v-icon {
  color: #4e4e4e !important;
}
</style>
