<template>
<div class="map" style="align-items:start">
    
 
    <div id="map2" style="width:100%; height:400px">
    </div>
</div>
</template>

<script>
export default {
    methods: {
        init() {
            var DG = require('2gis-maps');
            var
                map = DG.map('map2', {
                    'center': [57.112083, 65.574485],
                    'zoom': 16,
                    'closePopupOnClick': false
                });
            
            DG.popup({autoClose:false, closeButton:false})
                .setLatLng([57.111083, 65.574485])
                .setContent('<h4>Грузовичок</h4><br><h6>Николая Гондатти, 7</h6>')
                .openOn(map);

        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style>
.map{
    margin-top: 20px;
}
.dg-popup__container h4 {
    text-align: center;

}
.leaflet-container a.leaflet-popup-close-button{
    display: none;
}
h6{
    text-align: center;
    
    font-size: 18px;
    font-weight: normal;
    color: #ff6b00;
    white-space: nowrap;
    color:white
}
.map{
    width: 100%;
}
</style>
